export const environment = {
    production: true,
    apiHost: 'https://artsupplies.stellate.sh',
    //apiHost: 'https://api.artsupplies.co.uk',
    apiPort: 443,
    shopApiPath: '',
    // shopApiPath: 'shop-api',
    ssrApiHost: 'https://artsupplies.stellate.sh',
    // ssrApiHost: 'https://api.artsupplies.co.uk',
    ssrApiPort: 443,
    ssrShopApiPath: '',
    // ssrShopApiPath: 'shop-api',
    baseHref: '/',
    sentryDsn: 'https://e68c821c0c874b7f9f8faaf9b965c7b6@o1264498.ingest.sentry.io/6447424',
    sentryEnv: 'production',
    googlePayId: 'BCR2DN4TRKGOTYIC',
    recaptchaSiteKey: '6LfCn4YpAAAAAJrjWFz8mA4pTNtW2s6lvoD61aTD',
    dotDigitalAbandonedCartProgramId: 355061,
};
